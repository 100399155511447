export default {
  name: "FaceScan",
  props: {
    faceIdFound: {
      type: Boolean,
      default: false,
    },
    isLoadingFace: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: "",
    },
    strategy: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      video: null,
      canvas: null,
      imageData: "",
      currentStream: null,
      isGettingPermission: false,
    }
  },
  mounted() {
    this.video = this.$refs["videoElement"]
    this.canvas = this.$refs["canvas"]

    this.initCamera({
      video: { facingMode: "user" },
      audio: false,
      width: { min: 1280 },
      height: { min: 720 },
    })
  },
  beforeDestroy() {
    this.stopCamera()
  },
  methods: {
    appendStream: function (stream) {
      if (!navigator.allMediaStreams) {
        navigator.allMediaStreams = []
      }
      navigator.allMediaStreams.push(stream)
    },
    initCamera: function (config) {
      this.isGettingPermission = true
      this.getUserMedia(config)
        .then((stream) => {
          this.appendStream(stream)
          this.currentStream = stream
          this.video.srcObject = stream
          this.isGettingPermission = false
        })
        .catch((ex) => {
          this.$emit("cameraError", ex)
        })
    },
    getUserMedia: function (config) {
      return new Promise((resolve, reject) => {
        try {
          navigator.getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia

          navigator.mediaDevices
            .getUserMedia(config)
            .then((stream) => {
              if (!stream) {
                throw "NO STREAM"
              }
              this.appendStream(stream)
              resolve(stream)
            })
            .catch((ex) => {
              reject(
                ex && ex.name
                  ? `CAMERA_ERROR:${ex.name}`
                  : "CAMERA_ERROR:MEDIA_REJECTED"
              )
            })
        } catch (ex) {
          reject("CAMERA_ERROR:NO_MEDIA")
        }
      })
    },
    getPhoto: function () {
      this.video.pause()
      try {
        const canvasEl = this.canvas

        canvasEl.width = this.video.videoWidth
        canvasEl.height = this.video.videoHeight

        const backcontext = canvasEl.getContext("2d")

        backcontext.drawImage(this.video, 0, 0)

        this.imageData = canvasEl.toDataURL("image/png", 1.0)

        this.$emit("scan", this.imageData)

        return this.imageData
      } finally {
        this.video.play()
        this.imageData = null
      }
    },
    stopCamera: function () {
      if (navigator.allMediaStreams && navigator.allMediaStreams.length > 0) {
        let stream = this.video.srcObject

        navigator.allMediaStreams.forEach(() => {
          stream.getTracks().forEach((track) => {
            track.stop()
          })
          navigator.allMediaStreams.splice(
            navigator.allMediaStreams.indexOf(stream),
            1
          )
        })
      }
    },
  },
}
