<template>
  <z-card-modal
    persistent
    :opened="opened"
    :status-code="statusCode"
    width="400px"
    height="auto"
    class="card-container-recognition"
  >
    <template #content>
      <section v-if="statusCode === 408" id="erro-408">
        <h5 class="title-text text-uppercase text-left">
          {{ $t("recognition.recognition_error_quality") }}
        </h5>

        <p class="body2 my-3">
          {{ $t("recognition.recognition_error_quality_description") }}
        </p>
        <div class="text-content">
          <ul v-for="list in listItems" :key="list.text">
            <li class="d-flex align-center mb-3">
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </div>
      </section>
      <section v-else id="erro-404">
        <h5 class="title-text text-uppercase text-left">
          {{ $t("recognition.recognition_error_notFound") }}
        </h5>
        <p class="body2 my-3">
          {{ $t("recognition.recognition_error_notFound_description") }}
        </p>
        <p class="body2 my-3">
          {{ $t("recognition.recognition_error_notFound_description2") }}
        </p>
      </section>
      <div class="btn-container">
        <z-btn
          class="letsgo-btn my-2"
          primary
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '130px'"
          text="OK"
          @click="$emit('click:continue', ($event = statusCode))"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    statusCode: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const listItems = computed(() => {
      return [
        { text: i18n.tc("recognition.recognition_error_quality_list1") },
        { text: i18n.tc("recognition.recognition_error_quality_list2") },
        { text: i18n.tc("recognition.recognition_error_quality_list3") },
        { text: i18n.tc("recognition.recognition_error_quality_list4") },
      ]
    })

    return {
      listItems,
    }
  },
}
</script>

<style lang="scss" scoped>
//desktop
@media screen and (min-width: 786px) {
  .btn-container {
    display: flex;
    margin-top: 0;
  }

  .text-content {
    width: 90%;
    h4 {
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0 !important;
    }
  }
}
</style>
