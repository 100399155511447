<template>
  <div>
    <face-scan
      ref="faceScanner"
      :face-id-found="faceIdFound"
      :is-loading-face="isLoading || faceLoading"
      :strategy="strategy"
      :btn-text="
        strategy === 'facialRecognition'
          ? $t('recognition.faceScanner_buttonScan')
          : $t('recognition.facial_takepic_buttonCapture')
      "
      @scan="
        strategy === 'facialRecognition'
          ? handleScanFace($event)
          : handleTakePicture($event)
      "
    ></face-scan>
    <facial-recognition-error-modal
      v-if="openModalFacialError"
      :opened="openModalFacialError"
      :status-code="Number(statusCode)"
      @click:continue="handleContinueError($event)"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router/composables"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

import FacialRecognitionError from "../../views/Welcome/components/modals/FacialRecognitionError.vue"
import FaceScan from "./components/Camera/FaceScan.vue"

export default {
  components: {
    FacialRecognitionErrorModal: FacialRecognitionError,
    FaceScan,
  },
  props: {
    strategy: {
      type: String,
      default: "",
    },
  },

  setup() {
    const { changeFaceId } = useNamespacedMutations("search", ["changeFaceId"])
    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
    const { guest } = useNamespacedState("guest", ["guest"])
    const { saveFaceImage } = useStoreAction("guest", "saveFaceImage")
    const faceIdFound = ref(false)
    const openModalFacialError = ref(false)
    const faceLoading = ref(false)
    const { sendFaceImage } = useStoreAction("search", "sendFaceImage")
    const { searchReservation, isLoading, hasError, statusCode } =
      useStoreAction("search", "searchReservation")

    const router = useRouter()

    const handleScanFace = async (event) => {
      faceLoading.value = true
      changeFaceId({
        currentImage: event,
      })

      await sendFaceImage()

      await searchReservation()
      faceLoading.value = false

      if (statusCode === 200) {
        faceIdFound.value = true
      }

      if (hasError.value) {
        openModalFacialError.value = true
      }
    }

    const handleTakePicture = async (event) => {
      faceLoading.value = true

      if (guest.value.biometricsAccepted === true) {
        changeGuest({
          photoUrlMemory: event,
        })
      } else {
        changeGuest({
          photoUrlMemory: event,
          biometricsAccepted: false,
        })
      }

      await saveFaceImage()
      faceLoading.value = false

      router.push({ name: "personal-info" })
    }

    const handleContinueError = (statusCode) => {
      if (statusCode === 408) {
        openModalFacialError.value = false
      } else {
        openModalFacialError.value = false
        router.push({ name: "find-your-reservation" })
      }
    }

    return {
      changeFaceId,
      guest,
      handleScanFace,
      faceIdFound,
      isLoading,
      statusCode,
      searchReservation,
      handleTakePicture,
      handleContinueError,
      openModalFacialError,
      faceLoading,
    }
  },
}
</script>
